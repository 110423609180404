import { useEffect, useState } from 'react'
import { openSpinner, closeSpinner } from "@paytheory/pay-theory-ui"
import * as ROUTES from "../constants/routes";
const generateMenu = () => {
    return [{
            to: ROUTES.HOME,
            className: "active",
            tag: "home",
            icon: "building",
            label: "Home Page",
            isCategory: false
        }
    ];
};

const prepRoute = (route, replacements = []) => {
    return replacements.reduce((prepped, item) => {
        const keyed = `:${item.key}`
        return prepped.replace(keyed, item.value)
    }, route)
}

export {
    generateMenu,
    prepRoute
};

export const formatBasisPoints = (bp) => {
    const originalAmount = 100000;
    const totalAmount = Math.round(originalAmount / (1 - bp / 10000));
    const fee = totalAmount - originalAmount;
    return ((fee / originalAmount) * 100).toFixed(2);
  };


export const statusChip = {
    settled: {
      color: "mint",
      textColor: "black",
      text: "Settled"
    },
    reversed: {
      color: "yellow",
      textColor: "black",
      text: "Refunded"
    },
    pending: {
      color: "grey-2",
      textColor: "black",
      text: "Pending"
    },
    succeeded: {
      color: "blue",
      textColor: "white",
      text: "Received"
    },
    declined: {
      color: "red",
      textColor: "white",
      text: "Declined"
    }
  };

  export const paymentProfileStatusChip = [
   {
    color: "grey-2",
    textColor: "black",
    text: "Disabled"
  },{
    color: "mint",
    textColor: "black",
    text: "Enabled"
  }
]

export const formatFee = (fee) => {
    return fee < 0
      ? `-${(Math.abs(fee) / 100).toFixed(2)}`
      : `${(fee / 100).toFixed(2)}`;
  };

export const brandClasses = {
    VISA: "pay-theory-card-visa",
    DISCOVER: "pay-theory-card-discover",
    MASTERCARD: "pay-theory-card-mastercard",
    AMERICAN_EXPRESS: "pay-theory-card-american-express",
    CASH: "pay-theory-cash-badge",
    ACH: "pay-theory-ach-badge"
  };

  export const formatDate = (stamp) => {
    const dated = new Date(stamp);
    const month = dated.getMonth() + 1;
    const day = dated.getDate();
    const year = dated.getFullYear();
    return `${month}/${day}/${year}`;
  };
  
  export const formatFullDate = (stamp) => {
    const dated = new Date(stamp);
    const month = dated.getMonth() + 1;
    const day = dated.getDate();
    const year = dated.getFullYear();
    const hour = dated.getHours() % 12 || 12;
    const minute = dated.getMinutes();
    const formattedMinutes = minute < 10 ? `0${minute}` : minute;
    const amOrPm = dated.getHours() > 11 ? "PM" : "AM";
    return `${month}/${day}/${year} ${hour}:${formattedMinutes} ${amOrPm}`;
  };
  
  const dateArray = ["updated_at"];

  const mapValue = (value, next) => {
    if (value.includes("amount")) {
      return formatFee(next[`${value}`]);
    } else if (dateArray.includes(value)) {
      return formatFullDate(next[`${value}`]);
    } else {
      return next[`${value}`];
    }
  };
  
  function arrayToCSV(objArray) {
    if (objArray.length > 0) {
      const array =
        typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
      const str =
        `${Object.keys(array[0])
          .map((value) => `"${value}"`)
          .join(",")}\r\n`
  
      return array.reduce((str, next) => {
        str +=
          `${Object.keys(next)
            .map((value) => mapValue(value, next))
            .join(",")}\r\n`
        return str;
      }, str);
    }
  }
  
  export const downloadCSV = (items, fileName) => {
    var link = document.createElement("a");
  
    // Avoid scrolling to bottom
    link.style.top = "0";
    link.style.left = "0";
    link.style.position = "fixed";
  
    document.body.appendChild(link);
    const text = arrayToCSV(items);
    console.log("text", text);
    const data = new Blob([text], { type: "text/csv" });
    const url = URL.createObjectURL(data);
    link.href = url; 
    link.download = `${fileName}.csv`;
    link.onclick = (e) => {
      if (items.length === 0) e.preventDefault();
    };
    link.click();
  
    document.body.removeChild(link);
  };

export const convertAmount = (amount, currency = "USD") => {
    const nf = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2
    });
    return `${nf.format(amount / 100)} ${currency}`;
}